'use client'

import { useEffect } from 'react'
import { scan } from 'react-scan'

export function Scan() {
    useEffect(() => {
        scan({
            enabled: false,
            animationSpeed: 'off',
            log: false,

            showToolbar: typeof globalThis.window !== 'undefined' && process.env.NODE_ENV !== 'production',
        })
    }, [])

    return <></>
}
