import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/app/error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/components/analytics/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Scan"] */ "/app/apps/web/src/components/dev/scan.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationProvider"] */ "/app/apps/web/src/components/navigation/navigation-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/components/query/query-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/apps/web/src/components/theme/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryStreamedHydration"] */ "/app/node_modules/.pnpm/@tanstack+react-query-next-experimental@5.69.0_@tanstack+react-query@5.69.0_react@19.0.0__nex_p4n6dwtypr5hbsx4egbr3rjthi/node_modules/@tanstack/react-query-next-experimental/build/modern/ReactQueryStreamedHydration.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@4.0.2_next@15.2.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-react-co_fzrhliw4f53gvjtqp7idwmpi5a/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_fzmsqnasum23226rnp7uwcopli/node_modules/next/font/google/target.css?{\"path\":\"src/font.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin-ext\",\"greek\",\"cyrillic-ext\"],\"display\":\"swap\",\"preload\":true}],\"variableName\":\"jetbrains_mono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_fzmsqnasum23226rnp7uwcopli/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@15.2.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-react-compil_pdgz3sehuthpfoc4bbryb6jz34/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/app/node_modules/.pnpm/react-error-boundary@5.0.0_react@19.0.0/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/ui/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/globals.css");
