'use client'

import { isServer, QueryClient, QueryClientProvider as TansTackQueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function makeQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
                experimental_prefetchInRender: true,
            },
        },
    })
}

let browserQueryClient: QueryClient | undefined = undefined

function getQueryClient() {
    if (isServer) return makeQueryClient()

    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
}

export default function QueryClientProvider({ children }: { children: React.ReactNode }) {
    return (
        <TansTackQueryClientProvider client={getQueryClient()}>
            {children}

            <ReactQueryDevtools initialIsOpen={false} />
        </TansTackQueryClientProvider>
    )
}
